import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import { animated, useTransition } from 'react-spring'

import indexStyles from './index.module.scss'
import Layout from '../components/layout'
import SEO from '../components/seo'

const getLink = (imageFilename) => {
  switch (imageFilename) {
    // case 'fpb-caixa-fechada-fundo-transparente':
    //   return '/produtos/fpb'
    // case 'fps-caixa-fechada-fundo-transparente':
    //   return '/produtos/fps'
    default:
      return '/produtos'
  }
}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name, order: DESC }
        filter: { relativePath: { in: ["fpb-caixa-fechada-fundo-transparente.png", "fps-caixa-fechada-fundo-transparente.png"] } }
      ) {
        edges {
          node {
            name,
            relativePath,
            childImageSharp {
              fluid (quality: 10) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      },
      produtoPlaceholder: file(relativePath: { eq: "produto-placeholder-transparente.png" }) {
        childImageSharp {
          fluid (quality: 5){
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)

  const quantidadeProdutos = data.allFile.edges.length
  const prods = data.allFile.edges.map((edge) => {
    const produto = (
        <div className={indexStyles.imagemProduto}>
          <Link to={getLink(edge.node.name)}>
            <Img
              className={indexStyles.imgComponent}
              fluid={edge.node.childImageSharp.fluid}
              fadeIn={false}
            />
          </Link>
        </div>
    )
    return ({ style }) => <animated.div style={{ ...style}}>{produto}</animated.div>
  })

  const [index, set] = useState(0)
  // const onClick = useCallback(() => set(state => (state + 1) % quantidadeProdutos), [])
  // Havia um erro em mobile. O deslocamento era da direita para a esquerda,
  // isso fazia com que depois da animação, a tela voltava para o topo.
  // Mudamos a direção da animação, e não dá mais erro. Não deixamos dar zoom na página quando
  // no mobile (isso resolveu o problema de uma vez por toda)
  const transitions = useTransition(index, p => p, {
    from: { opacity: 0, transform: 'translateX(-100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(100%)' }
  })

  useEffect(() => {
    const id = setInterval(() => set(state => (state + 1) % quantidadeProdutos), 8000)
    // Clear when unmount
    return (() => {
      clearInterval(id)
    })
  }, [])

  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div className={indexStyles.mainContent}>
        <div className={indexStyles.produtosTexto}>
          <h1>
            <Link to="/produtos">
              Conheça os nossos produtos
            </Link>  
          </h1>
          <p>Fabricamos alarmes por presença há mais de 30 anos.</p>
          <div className={indexStyles.simpleTransMain} >
            <Img
              className={indexStyles.imgComponent}
              fluid={data.produtoPlaceholder.childImageSharp.fluid}
              fadeIn={false}
            />
            {transitions.map(({ item, props, key }) => {
              const Page = prods[item]
              return <Page key={key} style={props} />
            })}
          </div>
        </div>
      </div>
      <div className={indexStyles.containerLojaTexto}>
        <div className={indexStyles.mainContent}>
          <div className={indexStyles.lojaTexto}>
            <h1 className={indexStyles.title}>
              Onde encontrar?
            </h1>
            <p>Procure por uma loja mais perto de você.</p>
            <Link to="/lojas">Saiba mais</Link>
          </div>
        </div>
      </div>
      <div className={indexStyles.containerServicosTexto}>
        <div className={indexStyles.mainContent}>
          <div className={indexStyles.servicosTexto}>
            <h1 className={indexStyles.title}>
              Instalação ou manutenção?
            </h1>
            <p>Veja os serviços que disponibilizamos.</p>
            <Link to="/servicos">Saiba mais</Link>
          </div>
        </div>
      </div>
      <div className={indexStyles.containerContatoTexto}>
        <div className={indexStyles.mainContent}>
          <div className={indexStyles.contatoTexto}>
            <h1 className={indexStyles.title}>
              Dúvidas?
            </h1>
            <p>Entre em contato conosco.</p>
            <Link to="/contato">Saiba mais</Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
